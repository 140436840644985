import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';

import { BackOrderType } from './CreateBackOrder';
import { Order } from 'types/order.types';

import { TableCellTooltip } from 'components/shared/table/TableCell';
import { Checkbox } from 'components/ui/Checkbox';
import { Table } from 'components/shared/table';
import { Counter } from 'components/ui/Counter';
import { Modal } from 'components/ui/Modal';
import { formatDate } from 'util/dates';
import { orderBy } from 'lodash';

interface Props {
  order: Order;
  provider: string;
  items: BackOrderType[];
  setItems: React.Dispatch<React.SetStateAction<BackOrderType[]>>;
}

const nav = [
  '',
  '#',
  'Item',
  'Seller SKU',
  'MFR Code',
  'Order #',
  'QTY Ordered',
  'QTY Left',
  'QTY on BO',
  'Est. Ship Date'
];

export const CreateBackOrderTable = ({ order, provider, items, setItems }: Props) => {
  const [selectedItem, setSelectedItem] = useState<BackOrderType | null>(null);

  useEffect(() => {
    const res: BackOrderType[] = order.items
      .filter((el) => el.price.provider === provider)
      .map((el) => ({ ...el, isChecked: true, appliedQty: 0, eta: '' }));

    setItems(res);
  }, [order.items, provider, setItems]);

  const onToggleAll = () => {
    const cloned = [...items];
    const isChecked = items.length > 0 && items.every((el) => el.isChecked);
    const res = cloned.map((el) => ({ ...el, isChecked: !isChecked }));

    setItems(res);
  };

  const onToggleItem = (el: BackOrderType) => {
    const cloned = [...items];
    const index = cloned.findIndex(
      (item) => item.item.objectID === el.item.objectID && item.price.provider === el.price.provider
    );
    const item: BackOrderType = { ...cloned[index], isChecked: !cloned[index].isChecked };

    cloned[index] = item;

    setItems(cloned);
  };

  const onChangeQty = (el: BackOrderType, qty: number) => {
    const cloned = [...items];
    const index = cloned.findIndex(
      (item) => el.item.objectID === item.item.objectID && el.price.provider === item.price.provider
    );
    const item: BackOrderType = { ...cloned[index], appliedQty: qty };
    cloned[index] = item;

    setItems(cloned);
  };

  const onChangeEta = (date: Date) => {
    if (!selectedItem) return;

    setSelectedItem(null);
    const cloned = [...items];
    const index = cloned.findIndex(
      (item) => item.item.objectID === selectedItem.item.objectID && item.price.provider === selectedItem.price.provider
    );
    cloned[index] = { ...cloned[index], eta: date };

    setItems(cloned);
  };

  const isRowCovered = (el: BackOrderType) => {
    const allBackorderedItems = order.items.filter((item) => item.backOrder);

    const qty =
      allBackorderedItems.find(
        (item) => item.item.objectID === el.item.objectID && item.price.provider === el.price.provider
      )?.quantity || 0;

    return qty >= el.quantity;
  };

  return (
    <>
      <div className="selectAllCheckbox">
        <Checkbox isEnabled={items.length > 0 && items.every((el) => el.isChecked)} onChange={onToggleAll} />
        <p>{items.length > 0 && items.every((el) => el.isChecked) ? 'Unselect all' : 'Select all'}</p>
      </div>
      <Table
        name="CreateBackOrder"
        title="Backordered Items"
        allData={items}
        nav={nav}
        content={
          <div className="tableBody">
            {orderBy(items, (e) => isRowCovered(e)).map((item, index) => {
              const stackInfo =
                order.orderOverview.StackInfo.find(
                  (el) => el.VariantID === item.item.objectID && el.SupplierName === item.price.provider
                )?.Quantity || 0;

              return (
                <div
                  key={`${item.item.objectID}-${item.price.provider}`}
                  className={`tableRow ${isRowCovered(item) ? 'successRow' : 'dangerRow'}`}
                >
                  <Checkbox isEnabled={item.isChecked} onChange={() => onToggleItem(item)} />
                  <TableCellTooltip content={`${index + 1}.`} />
                  <TableCellTooltip content={item.item.title} />
                  <TableCellTooltip content={item.price.sku} />
                  <TableCellTooltip content={item.item.manufacturerCode} />
                  <TableCellTooltip content={order.orderID} />
                  <TableCellTooltip content={item.quantity} />
                  <TableCellTooltip content={stackInfo - item.appliedQty} />
                  <div className="tableCell">
                    <Counter quantity={item.appliedQty} onUpdateQuantity={(qty) => onChangeQty(item, qty)} />
                  </div>
                  <div className="tableCell">
                    <span onClick={() => setSelectedItem(item)}>{item.eta ? formatDate(item.eta) : 'Unknown'}</span>
                  </div>
                </div>
              );
            })}
          </div>
        }
      />
      {selectedItem && (
        <Modal open={true} close={() => setSelectedItem(null)} title="Select Date" className="ordersDatePicker">
          <Calendar onChange={onChangeEta} value={selectedItem.eta ? new Date(selectedItem.eta) : new Date()} />
        </Modal>
      )}
    </>
  );
};
